:not(:defined) {
  display: block;
}

a { outline: 0 !important; }

input:focus, input,
select:focus, select,
textarea:focus, textarea,
button:focus, button {
  outline: none !important;
}

:focus { outline: none !important; }

::-moz-focus-inner { border: 0 !important; }

.navbar-brand.crm {
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
}

html {
  position: relative;
  min-height: 100%;
  font-size: 14px;
}

body {
  margin: 0 0 3rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #767676;
  background-color: #f3f3f3;
}

nav.bg-blue {
  background-color: #0e4b82;
}

.navbar-dark .navbar-nav a.nav-link {
  color: #fff;
}

.navbar-toggler {
  color: #fff;
  font-size: 2rem;
}

.menu-search {
  background: #fff;
  color: #767676;
}

[href], .pointer, [ng-reflect-router-link] {
  cursor: pointer;
}

.crm-card {
  margin-top: 1rem;
}

.crm-app .card, .card {
  margin-bottom: 0.25rem;
}

.tight-card .card-body {
  padding: 0.5rem;
}

.tight-card .card-header {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.crm-app .card-footer, .card-footer {
  padding: .5rem 1rem;
}

.crm-white-card .card-header {
  background-color: #fff;
}

.crm-white-bg {
  background-color: #fff;
}

.crm-white-card {
  background-color: #FAFAFA;
}

select.form-control {
  height: 2.25rem;
}

.has-footer {
  min-height: 100vh;
  height: auto;
  margin: 0 auto -3rem;
  padding: 0 0 3rem;
}

.footer {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  width: 100%;
  height: 3rem;
  background-color: #0e4b82;
  color: #fff;
}

.inverse-primary, .bg-color-inverse {
  background-color: #0e4b82;
  color: #fff;
}

.crm-framed {
  margin: 0.75rem 0.5rem;
}

.crm-list-framed {
  margin: 0.25rem 0.5rem;
}

.card.crm-framed {
  padding: 0.75rem 0.5rem;
  display: block;
}

.text-inverse {
  color: #fff;
}

.card-secondary {
  background-color: #818a91;
  color: #fff;
}

.nav-link .material-icons, td.material-icons, .list-group-item .material-icons, .material-icons.sm, .material-icons-outlined.sm {
  font-size: 1rem;
}

.material-icons.md, .material-icons-outlined.md {
  font-size: 1.5rem;
}

.material-icons.in-button, .material-icons-outlined.in-button {
  top: 0.5rem;
  line-height: 0.1rem;
  position: relative;
}

.material-icons.in-text, .material-icons-outlined.in-text {
  top: 0.4rem;
  line-height: 0.1rem;
  position: relative;
}

.material-icons.in-link, .material-icons-outlined.in-link {
  top: 0.16rem;
  line-height: 0.1rem;
  position: relative;
}

.material-icons.in-text.left-space, .material-icons-outlined.in-text.left-space {
  margin-left: 1px;
}

.material-icons.sm.badge {
  font-size: 0.8rem;
}

.material-icons, .material-icons-outlined {
  position: relative;
  top: 2px;
  left: -1px;
  font-weight: 400 !important;
}

.material-icons.lg {
  top: 0.4rem;
}

.tight-row {
  margin: 0;
}

.row-no-sides {
  margin-left: 0;
  margin-right: 0;
}

.tight-row > * {
  padding-left: 0;
  padding-right: 0;
}

.tight-cell {
  padding-left: 0;
  padding-right: 0;
}

.crm-distr-logo {
  max-height: 55px;
}

.nav-tabs .nav-item, .nav-link {
  cursor: pointer;
}

td .label {
  font-size: 1rem;
}

.crm-td .label {
  font-size: 0.75rem;
  line-height: 1.4rem;
}

.crm-th {
  text-align: center;
  font-weight: bold;
  color: #fff;
  background-color: #777;
  border: 1px solid #ddd;
  font-size: 1rem;
  line-height: 1.8rem;
  padding: 0.2rem 0;
  white-space: nowrap;
}

.crm-td {
  text-align: left;
  font-weight: normal;
  display: table-cell;
  font-size: 1rem;
  line-height: 1.8rem;
  padding: 0.2rem 0.3rem;
  word-break: break-word;
  word-wrap: break-word;
}

.crm-bordered {
  border: 1px solid #ddd;
  border-top: none;
}

.crm-navbar-icon {
  font-size: 1.5rem !important;
  line-height: 5px;
  position: relative;
  top: 0.35rem;
}

.crm-row-pad {
  margin-top: 1rem;
}

.card-row-footer {
  padding-top: 0;
}

.crm-card-embedded {
  margin: 0.5rem;
}

.crm-card-embedded .card-body {
  padding: 0.5rem;
}

.crm-x-btn {
  cursor: pointer;
}

.crm-x-btn:hover, .crm-x-btn:focus, .crm-x-btn:active {
  color: red
}

.text-non-decorated {
  text-decoration: none;
}

.crm-sub-li {
  padding-left: 1.5rem;
}

@media (min-width: 1600px) {
  .hidden-xxl-up {
    display: none !important;
  }
}

@media (max-width: 1599px) {
  .hidden-xxl-down {
    display: none !important;
  }
}

.navbar-nav.btn-expander {
  background: #fff;
}

.navbar-nav.btn-expander:hover {
  opacity: .75;
}

.small-md-icon-container {
  max-height: 30px;
}

.small-md-icon {
  font-size: 24px;
  display: inline-block;
  padding-top: 7px;
  line-height: 0;
}

.nav-pad-expand {
  padding-top: 5px;
}

.card .list-group-item {
  overflow: auto;
}

.crm-single-line {
  white-space: nowrap;
  word-break: keep-all;
}

.crm-no-pad-left {
  padding-left: 0;
}

.crm-no-pad-right {
  padding-right: 0 !important;
}

.crm-logo-embedded {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: auto;
  text-align: center;
  display: inline-block;
}

.crm-distr-circle {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  text-align: center;
  display: inline-block;
  position: relative;
  border: rgba(0, 0, 0, 0.2) solid 1px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* deprecated */
.crm-distr-circle-container {
  float: left;
  width: 16.666%;
  text-align: center;
}

/* /// */

.crm-logo-distr {
  height: 50px;
}

.crm-distr-circle .material-icons {
  font-size: 3.4em;
  line-height: 1.3em;
  cursor: default;
}

.crm-lc-distr-left-half {
  padding-left: 2px;
  padding-right: 1px;
}

.crm-lc-distr-right-half {
  padding-right: 2px;
  padding-left: 1px;
  left: 5%;
}

.crm-sub-header {
  margin-top: 0.15rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: #1C7EBB;
}

.crm-header-descr {
  margin-top: 0.15rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: rgba(28, 126, 187, 0.78);
}

.crm-dd-highlight .dropdown-item:hover {
  color: #fff;
  background-color: rgba(28, 126, 187, 0.78);
}

.crm-dd-highlight a.dropdown-item {
  cursor: pointer;
}

.dropdown-item.with-badge {
  position: relative;
  margin-right: 15px;
}

.with-badge .badge {
  position: absolute;
  right: 5px;
  top: 5px;
}

.sl-group {
  color: black;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: .7rem;
  margin-bottom: .5rem;
  max-width: 13rem;
}

.crm-margin-bottom {
  margin-bottom: 1rem;
}

.crm-margin-bottom-half {
  margin-bottom: .5rem;
}

.crm-pointer {
  cursor: pointer;
}

a:not([class]), a[class=""] {
  color: #0275d8;
}

.color-primary {
  color: #0275d8 !important;
}

/* primary rgb: (2, 117, 216) */

.color-info {
  color: #2EC1CC !important;
}

.color-success {
  color: #1a8468 !important;
}

.color-warning {
  color: #f0ad4e !important;
}

.color-danger {
  color: red !important;
}

.color-danger-hover:hover {
  color: red !important;
}

.color-danger-hover {
  color: rgba(66, 6, 6, 0.6) !important;
}

.color-danger-dark {
  color: rgba(66, 6, 6, 0.6) !important;
}

.color-success-hover:hover {
  color: #17d637 !important;
}

.color-success-hover {
  color: rgba(6, 66, 6, 0.6) !important;
}

.color-success-dark {
  color: rgba(6, 66, 6, 0.6) !important;
}

.bg-color-danger {
  background-color: red !important;
}

.bg-color-danger-dark {
  background-color: rgb(175, 29, 29) !important;
}

.bg-color-success {
  background-color: #1a8468 !important;
}

.bg-color-success-light {
  background-color: #17d637 !important;
}

.color-success-light {
  color: #17d637 !important;
}

.bg-color-disabled {
  background-color: #ddd !important;
}

.bg-color-white {
  background-color: white !important;
}

.bg-color-primary-dark {
  background-color: #0e4b82 !important;
}

.bg-color-pre-danger {
  background-color: rgba(255, 0, 0, 0.1) !important;
}


.color-disabled {
  color: #ddd !important;
}

.color-hidden-info {
  cursor: default;
  color: rgba(2, 117, 216, 0.6) !important;
}

.color-white {
  color: white;
}

.new-distr-border {
  border: 1px solid #17d637;
}

.crm-header-primary {
  background-color: #dadadada;
  color: #0e4b82;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 2.3rem;
  padding-top: .5rem;
  padding-bottom: .25rem;
  padding-left: 1.5rem;
}

.color-black {
  color: black;
}

.crm-td-align-middle td {
  vertical-align: middle !important;
}

.crm-th-align-middle th {
  vertical-align: middle !important;
}

.btn.dropdown-toggle.crm-no-dropdown-arrow::after {
  display: none;
}

.tiny-header {
  padding: 0.5rem 1rem;
}

.highlight {
  background-color: #ffff00 !important;
}

.color-darken {
  background-color: rgba(0, 0, 0, 0.05);
}

.close.bright {
  opacity: 1;
  color: #fff;
}

.crm-header {
  margin: 1rem 0.5rem;
}

.crm-50mm-bar {
  width: 40%;
  overflow-x: hidden;
  float: left;
  background-color: rgb(196, 255, 155);
}

.crm-50mm-val {
  background-color: rgb(0, 190, 0);
  float: left;
}

.crm-50mm-label {
  color: white;
  font-weight: bold;
  padding-right: 0.2em;
}

.crm-50mm-light {
  color: rgb(196, 255, 155);
}

.crm-inline-sm-input-number {
  width: 3em;
  display: inline;
  padding: .2em .35em;
}

.card-header h4 {
  margin-bottom: 0;
}

.material-icons.header-icon {
  position: relative;
  line-height: 3.75rem;
  top: 7px;
  font-size: 2rem;
}

.crm-table-micro {
  margin-bottom: 0;
}

.crm-table-micro td, .crm-table-micro th {
  border-style: none;
  padding: 0;
}

.crm-table-micro .crm-rowspan2-centered {
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.crm-badge-inline {
  font-size: 90%;
  line-height: 0.9;
}

.crm-badge-line-el {
  margin-right: 0.5rem;
  font-size: .9rem;
}

.hidden {
  display: none !important;
}

.material-icons.in-card-header {
  line-height: 0.8;
  font-size: 1.5rem;
}

.material-icons.in-card-header-tall {
  font-size: 1.5rem;
}

.width-3rem {
  width: 3rem;
}

.btn-forced-outline-info {
  color: #2EC1CC; background-image: none; background-color: transparent; border-color: #2EC1CC;
  outline: none !important;
}

.btn-forced-outline-danger {
  color: #E94B3B; background-image: none; background-color: transparent; border-color: #E94B3B;
  outline: none !important;
}

.crm-up-8 {
  position: relative;
  top: -8px;
}

.crm-up-5 {
  position: relative;
  top: -5px;
}

.pointer-loading {
  cursor: progress;
}

.crm-fixed-height-5em {
  max-height: 5em;
  overflow: scroll;
}

.crm-fixed-height-15em {
  max-height: 15em;
  overflow-y: scroll;
}

.crm-tabbed {
  margin-left: 2rem;
}

.hitem-collapsed {
  overflow: hidden;
  max-height: 16em;
}

.underlined {
  text-decoration: underline;
}

.crm-p-line {
  padding: .5rem;
}

.crm-tight-card-section {
  padding: .75rem 1.25rem;
}

.crm-scrollable-content {
  overflow: auto;
  max-height: 70vh;
  padding-top: 0;
  padding-left: 0.25em;
}

.loctabexpanded .crm-scrollable-content {
  overflow: inherit;
  max-height: inherit;
  padding-top: 0;
  padding-left: 0.25em;
}

.th-centered th {
  text-align: center;
}

.bg-color-accented {
  background-color: rgba(46, 193, 204, 0.05);
}

.td-current-loc {
  border-bottom: rgba(46, 193, 204, 1) solid 2px;
  color: rgb(29, 110, 121);
}

.bg-color-accented-hover:hover {
  background-color: rgba(46, 193, 204, 0.05);
}

.bg-color-accented2 {
  background-color: rgba(46, 193, 204, 0.5);
}

.bg-color-accented2-hover:hover {
  background-color: rgba(46, 193, 204, 0.5);
}

.bg-color-accented3 {
  background-color: rgba(46, 193, 204, 0.10);
}

.bg-color-accented3-hover:hover {
  background-color: rgba(46, 193, 204, 0.10);
}

.bg-color-accented4 {
  background-color: rgba(46, 193, 204, 0.15);
}

.bg-color-accented4-hover:hover {
  background-color: rgba(46, 193, 204, 0.15);
}

.bg-color-white-hover:hover {
  background-color: #fff;
}

.modal-dialog.modal-wide {
  max-width: 70vw;
}

.modal-dialog.modal-wide50 {
  max-width: 50vw;
}

.modal-dialog.modal-high {
  min-height: 70vh;
}

.modal-dialog.modal-high50 {
  min-height: 50vh;
}

contract-chart {
  display: block;
}

.full-width {
  width: 100%
}

.twitter-typeahead {
  width: 100%
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0275d8;
}

.tt-suggestion {
  padding: 3px 20px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0275d8;
}

.tt-suggestion p {
  margin: 0;
}

.box-pad-right {
  margin-right: 1rem;
}

.crm-font-bold {
  font-weight: bold;
}

.crm-control-badge {
  padding: .375rem .75rem;
  width: 100%;
  top: 3px;
  position: relative;
  font-size: 1rem;
}

.card-tabs-container {
  padding: 7px 7px;
}

.card-tabs-container .nav-link.active, .card-tabs-container .nav-link.active:focus, .card-tabs-container .nav-link.active:hover {
  background-color: rgba(2, 117, 216, 0.1);
  border-color: rgba(2, 117, 216, 0.3) rgba(2, 117, 216, 0.3) transparent;
}

.card-tabs-container .nav-tabs {
  border-bottom: 1px solid rgba(2, 117, 216, 0.3);
}

.card-tabs-container .tab-content {
  padding-top: 7px;
}

.chart-badge {
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.85em;
  margin-bottom: 5px;
  cursor: pointer;
}

.chart-badge-hidden, .map-option-hidden {
  color: #999 !important;
  background-color: #ddd !important;
}

crm-chart {
  display: block;
}

locations-map path:hover {
  fill-opacity: .7;
}

.map-color-option {
  cursor: pointer;
  width: 100%;
  margin-bottom: 5px;
  padding: 2px;
  text-align: center;
}

.map-color-option.map-light-color {
  color: #000;
}

.map-color-option.map-dark-color {
  color: #fff;
}

.crm-icons-label-wide {
  margin: 0;
  min-width: 72px;
  top: -5px;
  position: relative;
}

.crm-icons-label {
  margin: 0;
  top: -5px;
  position: relative;
}

.crm-btm-border {
  border-bottom: solid black 2px;
}

.crm-sticky {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 1;
}

.crm-sticky-second {
  position: sticky;
  top: 2em;
  background-color: white;
  z-index: 1;
}

.crm-nearby-margin-left {
  margin-left: 0.75rem;
}

.crm-cs-even {
  background-color: #eee !important;
}

.half-transparent {
  opacity: 0.5;
}

.cursor-disabled, .cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-wait, .cursor-loading {
  cursor: wait;
}

.cursor-context, .cursor-modal {
  cursor: context-menu;
}

.cursor-help, .cursor-info {
  cursor: help;
}

.cursor-external {
  cursor: alias;
}

.cursor-none {
  cursor: none;
}

.rainbow-cycle-fast {
  background: linear-gradient(225deg, #ffabab, #ffdaab, #ddffab, #abe4ff, #d9abff);
  background-size: 300% 300%;
  -webkit-animation: Rainbow 2.5s ease infinite;
  -moz-animation: Rainbow 2.5s ease infinite;
  animation: Rainbow 2.5s ease infinite;
}

.rainbow-cycle-calm {
  background: linear-gradient(225deg, rgba(255, 168, 168, 0.51), rgba(255, 218, 171, 0.5), rgba(221, 255, 171, 0.51), rgba(171, 228, 255, 0.51), rgba(217, 171, 255, 0.5));
  background-size: 300% 300%;
  -webkit-animation: Rainbow 15s ease infinite;
  -moz-animation: Rainbow 15s ease infinite;
  animation: Rainbow 15s ease infinite;
}

.blue-cycle {
  background: linear-gradient(90deg, rgba(216, 216, 216, 0.35), rgba(14, 75, 160, 0.35), rgba(216, 216, 216, 0.35));
  background-size: 200% 200%;
  -webkit-animation: Rainbow 5s ease infinite;
  -moz-animation: Rainbow 5s ease infinite;
  animation: Rainbow 5s ease infinite;
}

@-webkit-keyframes Rainbow {
  0% {
    background-position: 94% 0%; }
  50% {
    background-position: 7% 100%; }
  100% {
    background-position: 94% 0%; }
}

@keyframes Rainbow {
  0% {
    background-position: 94% 0%; }
  50% {
    background-position: 7% 100%; }
  100% {
    background-position: 94% 0%; }
}

.opacity-cycle {
  -webkit-animation: opacityCycle 2.5s linear infinite;
  -moz-animation: opacityCycle 2.5s linear infinite;
  animation: opacityCycle 2.5s linear infinite;
}

@-webkit-keyframes opacityCycle {
  0% { opacity: 0.6 }
  50% { opacity: 0.15 }
  100% { opacity: 0.6 }
}

@keyframes opacityCycle {
  0% { opacity: 0.6 }
  50% { opacity: 0.15 }
  100% { opacity: 0.6 }
}

.contact-card {
  margin-bottom: 0;
}

.contact-card > .card-body {
  padding: 0.75rem;
}

.contact-card .card-title {
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
}

.material-icons.md-18 { font-size: 18px; }

.material-icons.md-24 { font-size: 24px; }

.material-icons.md-36 { font-size: 36px; }

.material-icons.md-48 { font-size: 48px; }

.container-btn-to-tabs-list {
  height: 0;
  text-align: right;
}

.btn-to-tabs-list {
  position: relative;
  top: -35px;
}

.crm-btn-heap {
  margin-bottom: 3px;
  margin-right: 3px;
}

.crm-location-title-same-as-group {
  margin-top: 0.8rem;
}

.font-sm {
  font-size: 0.9em;
}

.tbody-grayed-out {
  color: rgba(204, 204, 204, 0.3);
}

.color-primary-dark {
  color: #0e4b82;
}

.list-group.sm > .list-group-item {
  padding: 0.25em 0.5em;
}

.xs-header {
  padding: 0.25em 0.5em;
}

.loctabcardelexpaned {
  left: -38%;
  width: 138%
}

.loctabexpanded {
  left: -33%;
  width: 133%;
  position: relative;
}

.tab-expand-line {
  top: 15px;
  position: relative;
}

.pointer-ew-resize {
  cursor: ew-resize;
}

.tight-modal .modal-body {
  padding: 0.25rem;
}

.tight-modal .modal-footer {
  padding: 0.75rem;
}

.simple-list-item {
  border-bottom: solid 1px rgba(28, 126, 187, 0.7);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-bottom: 1px;
  padding-left: 0.5em;
  padding-right: 0.5rem;
}

.card-body.crm-card-container {
  margin-top: -2.75rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-bottom: 0;
}

.crm-control-label {
  padding-top: 0.375rem;
}

.crm-column-paddings-hor {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.card-header-inline {
  top: -1.5em;
  position: relative;
  height: 0;
}

.rx-scr-container {
  margin-right: 2px;
  padding: 2px;
  border-radius: 2px;
  border: 1px solid rgba(100, 100, 100, 0.25);
  float: left;
}

.rx-scr-img {
  min-height: 1rem;
}

.lc-distr-primary-badge {
  cursor: default;
  color: white;
  background-color: #0e4b82;
  border-radius: 3px;
  padding: 1px 4px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 700;
  font-size: 93%;
}

.crm-icon-btn {
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  padding: 2px 3px;
}

.crm-icon-btn.lowered {
  top: 2px;
}

.crm-col-no-overflow {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.crm-col-no-overflow * {
  text-overflow: ellipsis;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.crm-wait, .crm-loading, .crm-disabled, [disabled].animate-disabled {
  cursor: progress;
  -webkit-animation: opacityCycle 2.5s linear infinite;
  -moz-animation: opacityCycle 2.5s linear infinite;
  animation: opacityCycle 2.5s linear infinite;
}

a[target="_blank"] {
  cursor: alias;
}

.modal-title {
  width: 100%;
}

.crm-dropdown-menu-left {
  top: 1.05rem;
  left: -99%;
}

.dropdown-menu a {
  cursor: pointer;
}

.border-primary {
  border-color: #0275d8;
  border-style: solid;
  border-width: 1px;
}

.btn-success, .btn-info, .btn-danger, a.btn-success:not([href]), a.btn-info:not([href]), a.btn-danger:not([href]) {
  color: #fff;
}

.table-around-border {
  border: #ddd 1px solid;
  box-shadow: 1px 1px 3px 0 #00000038;
}

.material-icons.sm-height {
  line-height: .2em;
  top: .29em;
  position: relative;
}

.font-emoji-inline {
  font-size: 1.3em;
}

.font-1x {
  font-size: 1em;
}

.font-1_1x {
  font-size: 1.1em;
}

.font-1_2x {
  font-size: 1.2em;
}

.save-spaces, .save-newlines {
  white-space: pre-wrap;
}
